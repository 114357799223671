class Parts{
	constructor(_loadEnd){
		//console.log("new Parsts");
		const _this=this;
		const points=[new THREE.Vector3(180,0,0),
		new THREE.Vector3(0,0,0),
		new THREE.Vector3(0,-188,0),
		new THREE.Vector3(12,-188,0),
		new THREE.Vector3(12,-109,0),
		new THREE.Vector3(180,-109,0),
		new THREE.Vector3(180,0,0)];

		let earthObj;

		let animationMixer;
		let moveUnitTime=0.04;
		let animationClip;
		let clipAction;
		let animationFlag;

		

		let frontFlag;
		let flagRotation3D;
		let guideMesh;
		let canvasArea=$(".canvasarea");
		let message=$(".message");
		let messageCircle=$(".message__circle");
		let messageArea=$(".message-area");
		let svg=$(".message__svg");
		let svgCircle1=$(".cls-1",svg);
		let svgCircle2=$(".cls-2",svg);
		let svgPath=$(".path",svg);

		

		let scrollTop=0;

		$(window).bind("scroll",scrollHl).trigger("scroll");

		function scrollHl(){
			scrollTop=utils.scrollelement.scrollTop();
			setMessagePosition();
		}

		let fbxLoader=new THREE.FBXLoader();
		fbxLoader.addParseEvent(loadFbx);
		//console.log("load")
		fbxLoader.load("fbx/hataTest1.fbx.zip",1,function(){});

		var flagScene = new THREE.Scene();
		let flagShowState;
		//flagScene.add(THREE_BASE.light.clone());

		let anbientLight=new THREE.AmbientLight();
		anbientLight.copy(THREE_BASE.anbientLight1);
		let light=new THREE.DirectionalLight();
		let light1=new THREE.DirectionalLight();
		light.copy(THREE_BASE.light);
		light1.copy(THREE_BASE.light1);

		flagScene.add(anbientLight);
		flagScene.add(light);


		function loadFbx(e){


			_this.flagObj3d=new THREE.Object3D();
			animationFlag=e;

			$.each(animationFlag.children[1].material,(id,el)=>{
				el.side=THREE.DoubleSide;
				if(el.name=="sao"){
					el.color=new THREE.Color(0.4,0.5,0.5);
				}
			})

			animationFlag.scale.set(2,2,2);


			let flagGeometry=e.children[1].geometry.clone();
			flagGeometry.computeBoundingBox();
			let flagSize=flagGeometry.boundingBox.getSize(new THREE.Vector3());
			let flagMaterial=new THREE.MeshPhongMaterial({color:0xff00ff,side:THREE.DoubleSide,wireframe:true});
			let frag3D=new THREE.Mesh(flagGeometry,flagMaterial);


			animationMixer = new THREE.AnimationMixer(animationFlag);
			
			animationClip = animationFlag.animations[0];


			clipAction = animationMixer.clipAction(animationClip);

			clipAction.loop=THREE.LoopRepeat;
			
			animationMixer.time=0;

			clipAction.play();



			let transformY=new THREE.Vector3(0,flagSize.y,0);
			let rotateZ=new THREE.Euler(0,0,-Math.PI*0.2);
			frag3D.position.y=transformY.y;
			frag3D.rotation.z=rotateZ.z;
			frag3D.renderOrder=1000;

			transformY.multiplyScalar(-1);
			transformY.applyQuaternion(new THREE.Quaternion().setFromEuler(rotateZ));
			//frag3D.position.applyQuaternion(new THREE.Quaternion().setFromEuler(rotateZ).inverse());
			//frag3D.scale.set(flagScale,flagScale,flagScale)
			//frag3D.position.sub(transformY);

			frag3D.position.sub(transformY);
			frag3D.position.y-=flagSize.y;

			//animationFlag.position.copy(frag3D.position);
			
			animationFlag.quaternion.copy(frag3D.quaternion);

			guideMesh=new THREE.Mesh(new THREE.SphereBufferGeometry(3,16,8),new THREE.MeshStandardMaterial({color:0x667777,emissiveIntensity:0,metalness:0}));

			//console.log(guideMesh.material)
			flagRotation3D=new THREE.Object3D();
			//flagRotation3D.add(frag3D);
			//flagRotation3D.add(guideMesh);


			let flagPoint=new THREE.Vector3(0,1.015*EARTH.circleR/0.3,0);
			//let flagPointRotation=new THREE.Euler(Math.PI*0.75,Math.PI*0.5,Math.PI);//x軸に45度、z軸に半回転
			let flagPointRotation=new THREE.Euler(Math.PI*0.185,-Math.PI*0,-Math.PI*0.275);//x軸に45度、z軸に半回転
			flagPoint.applyEuler(flagPointRotation);

			//flagPoint.y-=10;
			//flagPoint.x+=20;


			_this.flagObj3d.position.copy(flagPoint);

			//flagRotation3D.position.y-=1.65;
			//flagRotation3D.position.x+=1.75;

			_this.flagObj3d.add(flagRotation3D);
			

			
			frontFlag=_this.flagObj3d.clone();
			
			frontFlag.children[0].add(animationFlag);
			frontFlag.children[0].add(guideMesh);


			flagScene.add(frontFlag);
			//flagScene.add(e);

			//console.log("frag3D")
			//console.log(frag3D)


			_this.flagObj3d.visible=false;


			rayReceiveObjects.push(frontFlag.children[0].children[0].children[1]);


			_loadEnd();


		}

		let layReceiveObjEarth=[];

		this.setEarthObj3D=(_earth)=>{
			earthObj=_earth;
			$.each(earthObj.children,(id,el)=>{
				if(el.name=="mizuObj3D"){
					layReceiveObjEarth.push(el.children[0]);
				}else if(el.name=="chikyuObj3D"){
					//console.log("chikyuObj3D")
					//console.log(el.children)
					$.each(el.children,(id0,element)=>{
						layReceiveObjEarth.push(element);
					})
				}
			})
			//console.log("earthObj,layReceiveObjEarth=");
			//console.log(layReceiveObjEarth)
		}

		

		let flagScaleCount=0,flagScaleRatio;
		let flagScaleCountMax=50;
		let flagScaleDir=0;
		let shortRatio=0;

		
		let flagRotationCountMax=25;
		let flagRotationCount=flagRotationCountMax,flagRotationRatio;
		let flagRotationDir=1;
		let TempP;
		let winW,winH;
		let rayCastP=new THREE.Vector2();
		let rayReceiveObjects=[];
		//let getP;

		this.rayCaster=new THREE.Raycaster();

		let mouseP=new THREE.Vector2();
		let mouseP2=new THREE.Vector2();
		let messageP=new THREE.Vector2();

		let mouseDownState;
		let mouseMoveCount=0;

		this.mouseDownHl=(_x,_y)=>{
			mouseDownState=true;
		}

		this.mouseUpHl=(_x,_y)=>{
			mouseDownState=false;
		}

		this.mouseMoveHl=(_x,_y)=>{
			mouseP.x=_x;
			mouseP.y=_y;

			mouseP2.copy(mouseP);
			mouseP2.y+=scrollTop;

			//trace("parts.js mouseMoveHl,"+[_x,_y])
			//trace2("mouseMoveHl,"+[_x,_y])
			//console.log("mouseMoveHl,"+[_x,_y])
			let canvasAreaH=canvasArea.height();
			rayCastP.x=1-2*(winW-_x)/winW;
			rayCastP.y=-(1-2*(winH-_y-scrollTop)/winH);


			//trace2(mouseMoveCount+":intersectsEarth,"+intersectsEarth);
			//trace(rayCastP.x+" : "+rayCastP.y)
			//trace(mouseP)


			this.rayCaster.setFromCamera(rayCastP,THREE_BASE.camera);
			let intersects=this.rayCaster.intersectObjects(rayReceiveObjects);

			let intersectsEarth=this.rayCaster.intersectObjects(layReceiveObjEarth);
			//trace(intersectsEarth)

			if(intersectsEarth.length>0){
				
				THREE_BASE.earthOn=true;
			}else{
				THREE_BASE.earthOn=false;
			}
			mouseMoveCount+=1;

			//console.log("intersectsEarth,"+intersectsEarth)


			//console.log("mouseMoveHl:"+[_x,_y])

			if(messageP.distanceTo(mouseP2)<messageCircle.width()/1){
				if(!messageCircle.hasClass("small")){
					//messageCircle.addClass("small")
					flagShow();
					messageShow();
				}
			}else{
				//trace2("intersects="+intersects.length)
				if(messageCircle.hasClass("small")){
					if(intersects.length<1){
						//旗にマウスオンでない
						//messageHide();
						//flagHide();
					}
					//messageCircle.removeClass("small")
					//flagHide();
				}
			}
			if(utils.hitCheck(messageArea,mouseP2) && message.hasClass("show" )){
				canvasArea.addClass("cursol_pointer");
			}else{
				canvasArea.removeClass("cursol_pointer");
				
				if(messageP.distanceTo(mouseP2)>2*messageCircle.width() && intersects.length<1){
					messageHide();
					if(flagShowState){
						flagHide();
						//console.log("flagHide")
					}

					
				}
			}

			//trace(rayCastP)
		}

		this.clickHl=(mouseP)=>{
			//console.log("clickHl="+[mouseP.x,mouseP.y]);
			//console.log(messageP);

			//trace2("scrollTop="+scrollTop)
			
			mouseP.y+=scrollTop;

			//クリックでメッセージ出す

			/*if(messageP.distanceTo(mouseP)<messageCircle.width()/2){
				if(!message.hasClass("show")){
					messageShow();
				}
			}else{
				if(!message.hasClass("small")){
				
				}
			}*/

			//console.log("rayCastP="+[rayCastP.x,rayCastP.y])

			this.rayCaster.setFromCamera(rayCastP,THREE_BASE.camera);
			let intersects=this.rayCaster.intersectObjects(rayReceiveObjects);


			if(intersects.length>0){
				if(!message.hasClass("show")){
					messageShow();
				}
			}

			if(utils.hitCheck(messageArea,mouseP)){
				let scale=Number(message.css("transform").split(",")[0].replace("matrix(",""));
				if(scale>0.5){
					utils.jump("http://www.31ventures.jp/","_blank");
				}
			}
		
		};

		this.resizeHl=(_w,_h)=>{
			winW=_w;winH=_h;
			setMessagePosition();
		}
		

		this.update=()=>{

		

			animationMixer.update(moveUnitTime);

			//return;

			let fragQuaternion=this.flagObj3d.getWorldQuaternion(new THREE.Quaternion());
			flagRotation3D.quaternion.copy(fragQuaternion.inverse());

			TempP=this.flagObj3d.getWorldPosition(new THREE.Vector3());
			

			//frontFlag.position.copy(TempP);

			//地球の裏側に移動したら速やかに消える

			if(flagScaleCount>=flagScaleCountMax){
				flagScaleCount=flagScaleCountMax;
			}else if(flagScaleCount<=0){
				flagScaleCount=1;
			}

			flagScaleRatio=1-(1+Math.cos(Math.PI*flagScaleCount/flagScaleCountMax))/2;
			flagScaleCount+=flagScaleDir;

			frontFlag.children[0].scale.set(flagScaleRatio,flagScaleRatio,flagScaleRatio);

			shortRatio=0.05*(Math.pow(flagScaleRatio,2))+0.95;


			let getP=getStopP(TempP);
			
			
			if(TempP.z<getP.z){
				flagScaleDir=-1;
				frontFlag.position.copy(getP.multiplyScalar(shortRatio));
				flagShowState=false;
				messageHide();
				//console.log("ushiro");
				messageCircle.addClass("small");
				//message.addClass("small");
			}else{
				if(flagShowState){
					flagScaleDir=1;

					
				}else{
					frontFlag.position.copy(TempP);
					
					//flagScaleDir=-1;
				}
				//console.log("mae")
				frontFlag.position.copy(TempP);
				messageCircle.removeClass("small");
			}

			//地球の左に移動したら反転


			if(TempP.x>=0){
				flagRotationDir=1
			}else{
				flagRotationDir=-1
			}

			if(flagRotationCount>=flagRotationCountMax){
				flagRotationCount=flagRotationCountMax;
			}else if(flagRotationCount<=0){
				flagRotationCount=0;
			}

			flagRotationRatio=1-(1+Math.cos(Math.PI*flagRotationCount/flagRotationCountMax))/2;
			flagRotationCount+=flagRotationDir;
			frontFlag.children[0].rotation.y=(1-flagRotationRatio)*Math.PI;

			setMessagePosition();

			if(!mouseDownState){
				//console.log("mouseMoveHl,"+[mouseP.x,mouseP.y])
				this.mouseMoveHl(mouseP.x,mouseP.y)
			}

			

			THREE_BASE.renderer.clearDepth();

			this.rayCaster.setFromCamera(rayCastP,THREE_BASE.camera);

			//console.log(rayCastP);
			//console.log(this.rayCaster);
			//console.log(rayReceiveObjects)

			let intersects=this.rayCaster.intersectObjects(rayReceiveObjects);
			//trace2(intersects.length)
			if(intersects.length>0){
				/*if(!messageCircle.hasClass("small")){
					flagShow();
				}*/
			}

			//return;
			//前面にレンダリング

			THREE_BASE.renderer.render(flagScene,THREE_BASE.camera);



		}

		function flagShow(){
			//console.log("flagShow")
			flagShowState=true;
			flagScaleDir=1;
		}

		function flagHide(){
			flagShowState=false;
			flagScaleDir=-1;
			if(messageCircle.hasClass("small")){
				//messageCircle.removeClass("small")
			}
			//console.log("flagHide")
		}

		function messageShow(){
			//console.log("messageShow")
			THREE_BASE.stopMove();
			message.addClass("show");
		}

		function messageHide(){
			//console.log("messageHide")
			THREE_BASE.resetMove();
			message.removeClass("show");
		}

		let svgMargin=new THREE.Vector2(-250,-150);
		let lastCirclP=new THREE.Vector2();

		function setMessagePosition(){
			//console.log("setMessagePosition")

			if(TempP){
			
				let screenPosition=TempP.clone().project(THREE_BASE.camera);
				

				if(flagScaleDir==-1){
					message.addClass("small");
					//messageCircle.addClass("small");
				}else if(flagScaleDir==1){
					message.removeClass("small");
					//messageCircle.removeClass("small");
				}else{

				}

				let messageAreaX=-50-40*(TempP.x/170)+"%";
				//let messageAreaY=-50-40*(TempP.y/170)+"%";
				let messageAreaY="100%";

				//TweenMax.set(messageArea,{left:messageAreaLeft});
				messageArea.css("transform","translate("+messageAreaX+","+messageAreaY+")");

				messageP.x=winW*(1+screenPosition.x)/2;
				messageP.y=winH*(1-screenPosition.y)/2;


				let center=new THREE.Vector2(winW/2-THREE_BASE.camera.view.offsetX,winH/2-THREE_BASE.camera.view.offsetY)


				if(messageCircle.hasClass("small")){
					messageP.x=lastCirclP.x-(lastCirclP.x-center.x)*(1-shortRatio);
					messageP.y=lastCirclP.y-(lastCirclP.y-center.y)*(1-shortRatio);

				}else{
					
					lastCirclP.copy(messageP);
				}

				messageP.x=Math.floor(messageP.x);
				messageP.y=Math.floor(messageP.y);


				message.css({"left":messageP.x+"px","top":messageP.y+"px"});
				messageCircle.css({"left":messageP.x+"px","top":messageP.y+"px"});

				//messageCircle.css({"left":center.x+"px","top":center.y+"px"});


				if(messageP.distanceTo(mouseP)>messageCircle.width()/2){
					if(!messageCircle.hasClass("small")){
						//messageCircle.addClass("small")
						//flagShow();
					}
				}else{
					if(!messageCircle.hasClass("small")){
						//messageCircle.removeClass("small")
						//flagHide();
					}
				}
				


				let pointRadius=winW*0.004;//丸のサイズ

				//console.log("setMessagePosition")


				svg.css({left:svgMargin.x-pointRadius+"px",top:svgMargin.y-pointRadius+"px"});
				svgCircle1.attr({"cx":pointRadius,"cy":pointRadius,"r":pointRadius});
				let messagePosition=message.offset();
				let messageAreaPosition=messageArea.offset();

				//trace2("messagePosition.left="+messagePosition.left+","+messagePosition.top);
				//trace("messageAreaPosition="+messageAreaPosition.left+","+messageAreaPosition.top)
				let circleX=-messagePosition.left+messageAreaPosition.left+messageArea.width()/2;
				let circleY=-messagePosition.top+messageAreaPosition.top;


				if(circleX<svgMargin.x+pointRadius){
					circleX=svgMargin.x+pointRadius;
				}
				if(circleY<svgMargin.y+pointRadius){
					circleY=svgMargin.y+pointRadius;
				}


				let messageScale=Number(message.css("transform").split(",")[0].replace("matrix(",""));

				//console.log("svgCircle2="+svgCircle2)
				//console.log( svgCircle2)

				svgCircle2.attr({"cx":circleX*messageScale,"cy":circleY*messageScale+pointRadius/1,"r":pointRadius*messageScale});

				let P1={x:0,y:0};
				let P2={x:circleX,y:circleY};
				let Prad={x:0.5*svgMargin.x,y:0.5*svgMargin.y};
				let Pstart=60-90+180*(Math.atan2(P2.y,P2.x)/Math.PI);//傾き

				if(Math.abs(P2.x)<100){
					let radRatioX=Math.pow(Math.abs(P2.x)/100,5);
					let radRatioY=Math.pow(Math.abs(P2.x)/100,1);
					Pstart=60*radRatioY-90+180*(Math.atan2(P2.y,P2.x)/Math.PI);//傾き;
					Prad.x*=radRatioY;
					//Prad.y*=radRatioY;
					if(Math.abs(P2.x)<10){
						Prad.x=0;
						Prad.y=0;
					}

				}
				
				let f2;
				if(P2.x>0){
					f2=1;
				}else{
					f2=0;
				}


				let Pattr="M "+(P1.x+pointRadius)+" "+(P1.y+pointRadius)+" A "+Prad.x+" "+Prad.y+" "+Pstart+" 0 "+f2+" "+P2.x+","+P2.y;
				svgPath.attr({"d":Pattr});
				
			}
		}


		function getStopP(v3){
			let distance=THREE_BASE.camera.position.length();//円の中心が0,0,0の場合
			let circleL=1.01*EARTH.circleR;
			let d=Math.asin(circleL/distance);//接線の、なす角度
		
			let Bt=distance*Math.cos(d);
			let Bz=distance-Bt*Math.cos(d);

			let R=Bt*Math.sin(d);//接線が描く円の半径

			let d2=Math.asin(v3.y/R);
			let Rx=R*Math.cos(d2);
			if(v3.x<0){
				Rx*=-1;
			}
			return new THREE.Vector3(Rx,v3.y,Bz);
		}      

	}

}